import { useSiteConfig } from "#imports";
import { ref } from "vue";
export function useSchemaOrg() {
}
export function defineWebSite() {
}
export function defineWebPage() {
}
export function defineBreadcrumb() {
}
export function useI18n() {
  const siteConfig = useSiteConfig();
  return {
    // eslint-disable-next-line unused-imports/no-unused-vars
    t: (_, fallback, options) => fallback,
    te: (_) => false,
    strategy: "no_prefix",
    defaultLocale: ref(siteConfig.defaultLocale || "en"),
    locale: ref(siteConfig.currentLocale || siteConfig.defaultLocale || "en")
  };
}
