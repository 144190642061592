import {
  createSitePathResolver,
  defineBreadcrumb,
  useI18n,
  useSchemaOrg,
  useSiteConfig
} from "#imports";
import { defu } from "defu";
import { useRouter } from "nuxt/app";
import { fixSlashes } from "nuxt-site-config/urls";
import { withoutTrailingSlash } from "ufo";
import { computed, toValue } from "vue";
import { pathBreadcrumbSegments } from "../../shared/breadcrumbs.js";
function withoutQuery(path) {
  return path.split("?")[0];
}
function titleCase(s) {
  return s.replaceAll("-", " ").replace(/\w\S*/g, (w) => w.charAt(0).toUpperCase() + w.substr(1).toLowerCase());
}
export function useBreadcrumbItems(options = {}) {
  const router = useRouter();
  const i18n = useI18n();
  const siteResolver = createSitePathResolver({
    canonical: true,
    absolute: true
  });
  const siteConfig = useSiteConfig();
  const items = computed(() => {
    let rootNode = "/";
    if (i18n) {
      if (i18n.strategy === "prefix" || i18n.strategy !== "no_prefix" && toValue(i18n.defaultLocale) !== toValue(i18n.locale))
        rootNode = `/${toValue(i18n.locale)}`;
    }
    const current = withoutQuery(withoutTrailingSlash(toValue(options.path || router.currentRoute.value?.path) || rootNode));
    const overrides = toValue(options.overrides) || [];
    const segments = pathBreadcrumbSegments(current, rootNode).map((path, index) => {
      let item = {
        to: path
      };
      if (typeof overrides[index] !== "undefined") {
        if (overrides[index] === false)
          return false;
        item = defu(overrides[index], item);
      }
      return item;
    });
    if (options.prepend)
      segments.unshift(...toValue(options.prepend));
    if (options.append)
      segments.push(...toValue(options.append));
    return segments.filter(Boolean).map((item) => {
      const route = router.resolve(item.to)?.matched?.[0] || router.currentRoute.value;
      const routeMeta = route?.meta || {};
      const routeName = route ? String(route.name.split("___")) : item.to === "/" ? "index" : null;
      const fallbackLabel = titleCase(routeName === "index" ? "Home" : (item.to || "").split("/").pop() || "");
      if (routeMeta.breadcrumb) {
        item = {
          ...item,
          ...routeMeta.breadcrumb
        };
      }
      item.label = item.label || routeMeta.title || routeMeta.breadcrumbTitle || i18n.t(`breadcrumb.items.${routeName}.label`, fallbackLabel, { missingWarn: false });
      item.ariaLabel = item.ariaLabel || i18n.t(`breadcrumb.items.${routeName}.ariaLabel`, item.label, { missingWarn: false }) || item.label;
      item.current = item.current || item.to === current;
      if (toValue(options.hideCurrent) && item.current)
        return false;
      return item;
    }).map((m) => {
      if (m && m.to) {
        m.to = fixSlashes(siteConfig.trailingSlash, m.to);
        if (m.to === rootNode && toValue(options.hideRoot))
          return false;
      }
      return m;
    }).filter(Boolean);
  });
  const schemaOrgEnabled = typeof options.schemaOrg === "undefined" ? true : options.schemaOrg;
  if ((import.meta.dev || import.meta.server) && schemaOrgEnabled) {
    useSchemaOrg([
      defineBreadcrumb({
        id: `#${options.id || "breadcrumb"}`,
        itemListElement: computed(() => items.value.map((item) => ({
          name: item.label || item.ariaLabel,
          item: item.to ? siteResolver(item.to) : void 0
        })))
      })
    ]);
  }
  return items;
}
